import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import MainContent from './components/MainContent';
import styles from './styles/App.module.css';

const App: React.FC = () => {
  return (
    <div className={styles.container}>
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
}

export default App;
