import React from 'react';
import './Header.css';

const Header: React.FC = () => {
  return (
    <header className="header">
      <h1>
        <span className="welcome-text">welcome to...</span>
        <span>   </span>
        <span className="company-name">feras information technologies</span>
      </h1>
    </header>
  );
}

export default Header;
