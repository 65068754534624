import React from 'react';
import './MainContent.css';

const MainContent: React.FC = () => {
  return (
    <div>
        <div className="block-text">
          A small corner of the web that is the home of a software consulting firm with decades of experience
          in a wide variety of programming languages and software technologies. From process control and mobile apps,
          to AI and Ethereum blockchain protocols, we continue to stay on the cutting edge to bring you solutions.
        </div>
        <p></p>
        <div className='normal-text'>
        See a summary of our experiences via <a href="https://www.linkedin.com/in/john-feras-749268">LinkedIn</a>.
        </div>
        <a href="mailto:jferas@ferasinfotech.com" className="contact-button">Contact Us</a>
        <p></p>
    </div>
  );
}

export default MainContent;
