import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <p>© 2023 Feras Information Technologies. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
